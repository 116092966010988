import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { authApis } from '../core/authApis';

@Injectable({
  providedIn: 'root',
})
export class AuthsService {
  AGENT_ID: any = '';
  TOKEN_KEY: any = '';
  ROLE: any = '';
  ADMIN_EMAIL: any = '';
  ADMIN_ID: any = '';
  ADMIN_TOKEN_KEY: any = '';
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  adminloggedIn: boolean = false;
  userloggedIn: boolean = false;

  constructor(private http: HttpClient, private router: Router) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();

    this.updateAdminInfo();
    this.updateUserInfo();
    this.router.events
      .pipe(filter((e: any) => e instanceof NavigationEnd))
      .subscribe((res: any) => {
        this.updateAdminInfo();
        this.updateUserInfo();
      });
  }

  updateUserInfo() {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
    if (Object.keys(userInfo).length) {
      this.ROLE = userInfo.role;
      this.setUserLogged();
      this.TOKEN_KEY = userInfo.token;
      this.AGENT_ID = userInfo.agent_id;
    }
  }

  updateAdminInfo() {
    let adminInfo = JSON.parse(sessionStorage.getItem('adminInfo') || '{}');
    if (Object.keys(adminInfo).length) {
      this.ROLE = adminInfo.role;
      this.setAdminLogged();
      this.ADMIN_TOKEN_KEY = adminInfo.token;
      this.ADMIN_EMAIL = adminInfo.email;
      this.ADMIN_ID = adminInfo.id;
    }
  }

  setUserLogged() {
    this.userloggedIn = true;
  }

  setUserloggedOut() {
    this.userloggedIn = false;
  }

  getUserLoggedIn() {
    return this.userloggedIn;
  }

  setAdminLogged() {
    this.adminloggedIn = true;
  }

  setAdminloggedOut() {
    this.adminloggedIn = false;
  }

  getAdminLoggedIn() {
    return this.adminloggedIn;
  }

  adminSignIn(data: any): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<{}>(authApis.adminlogin, data, { headers });
  }

  forgotPasswordSendOTP(data: any): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<{}>(authApis.adminSendOTPForgotPassword, data, {
      headers,
    });
  }

  forgotPasswordWithOTPAndPassword(data: any): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<{}>(authApis.adminForgotPasswordComplete, data, {
      headers,
    });
  }

  userSignIn(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<{}>(authApis.userlogin, data, { headers });
  }

  userSignUp(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<{}>(authApis.userSignUp, data, { headers });
  }

  generateAgreement(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<{}>(authApis.generateAgreement, data, { headers });
  }

  agentSignOut() {
    this.userloggedIn = false;
    this.AGENT_ID = '';
    this.TOKEN_KEY = '';
    this.ROLE = '';
    this.router.navigate(['/auth/login']);
    sessionStorage.clear();
  }

  adminSignOut() {
    this.adminloggedIn = false;
    this.ADMIN_EMAIL = '';
    this.ADMIN_ID = '';
    this.ADMIN_TOKEN_KEY = '';
    this.ROLE = '';
    this.router.navigate(['/auth/admin-login']);
    sessionStorage.clear();
  }
}
