import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthsService } from '../services/auths.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthsService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // if(request.method !== 'GET'){
    let reqBody: any = request.body;

    if (this.authService.ROLE === 'ADMIN') {
      const adminToken = this.authService.ADMIN_TOKEN_KEY;
      // console.log('adminToken', adminToken);
      if (adminToken) {
        request = request.clone({
          setHeaders: { 'X-Admin-Token': adminToken },
          body: Object.assign(reqBody),
        });
      }
    }

    if (this.authService.ROLE === 'AGENT') {
      let token = this.authService.TOKEN_KEY;
      if (token) {
        // let global: any = {};
        request = request.clone({
          setHeaders: { 'X-User-Token': token },
          body: Object.assign(reqBody),
        });
      }
    }
    // }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg: any = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = { Message: `${error.error.message}` };
        } else {
          errorMsg = {
            ErrorCode: `${error.status}`,
            Message: `${error.status} : ${error.statusText}`,
          };
        }
        return throwError(() => errorMsg);
      })
    );
  }
}
