import { Constant } from './appConstant';
const baseUrl = (url: any) => Constant.authBaseUrl + url;
export class authApis {
  /* ADMIN */
  public static adminlogin = '/api/adminLogin';
  public static adminSendOTPForgotPassword = '/api/admin/sendotp';
  public static adminForgotPasswordComplete = '/api/admin/forgetPass';

  /* AGENT */
  public static userlogin = '/api/userLogin';
  public static userSignUp = '/api/userRegister';
  public static userRegister = '/api/userRegister';

  public static generateAgreement = '/api/docsign/genrateagreement';
}
