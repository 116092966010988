import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthsService } from '../services/auths.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService:AuthsService, private router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(this.authService.ROLE === 'AGENT') {
        if(!this.authService.getUserLoggedIn()){
          this.router.navigate(['/auth/login']);
        }         
      }
      if(this.authService.ROLE === 'ADMIN') {
        if(!this.authService.getAdminLoggedIn()){
          this.router.navigate(['/auth/admin-login']);
        }         
      }
      return this.authService.ROLE === 'ADMIN' ? this.authService.getAdminLoggedIn() : this.authService.getUserLoggedIn();
  }
 
}
